<template>
  <div class="diagnosis">
    <h3>{{ $t('diagnosis.heading') }}</h3>
    <p>{{ $t('diagnosis.description') }}</p>
    <van-list>
      <van-button
        v-for="item in list"
        :key="item.name"
        type="hollow-white"
        class="condition"
        :class="item.name === selectedCondition ? 'selected' : null"
        @click="selectedCondition = item.name"
      >
        <span class="condition__name">{{ item.name }}</span>
        <span class="condition__title">{{ item.title }}</span>
      </van-button>
      <van-button
        v-if="!!selectedCondition"
        :text="$t('continue')"
        :to="{ name: 'values' }"
        size="large"
        type="white"
        class="continue"
        @click="saveCondition"
      />
    </van-list>
  </div>
</template>

<script>
import { diagnoses } from '@/models/diagnosis';

export default {
  data() {
    return {
      selectedCondition: null,
      list: diagnoses,
    };
  },
  methods: {
    saveCondition() {
      this.$store.commit('user/setDiagnosisName', this.selectedCondition);
    },
  },
};
</script>

<style lang="scss" scoped>
@use '~@/styles/config' as config;

.diagnosis {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 0 config.$spacing config.$spacing;
  text-align: center;
}

.condition {
  margin-bottom: 1em;

  .van-button__text {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0 15px;
    text-align: left;
  }

  &__name {
    flex: 1;
    max-width: 4rem;
    margin-right: 0.6rem;
  }

  &__title {
    flex: 1;
  }

  &.selected {
    background-color: #fff;
    color: config.$brand-primary;
  }
}

.continue {
  margin-top: 1.375rem;
}
</style>
